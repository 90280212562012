import React, { useContext, useEffect, useState } from 'react';
import { AccountCircle, ArrowBack, Logout } from '@mui/icons-material';
import {
  AppBar,
  Box,
  Drawer,
  IconButton,
  List,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Theme,
  Toolbar
} from '@mui/material';
import { useLocation, useNavigate } from 'react-router-dom';
import { AppContext } from '../../AppContext';
import { Actions } from '../../enums/ActionEnums';
import { AppConsts } from '../../enums/AppConsts';
import logo from '../../assets/logo.svg';
//@ts-ignore
import hand from '../../assets/hand.png';
import { IRouteModel } from '../../Routes';
import ListItemLink from './ListItemLink';
import { colors } from '../../utils/theme';
import ListGroupLink from './ListGroupLink';
import BrandSelect from './BrandSelect';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import { getBrandId } from '../../utils/ui';
import { QueryKey } from '../../enums/HttpRequestKeyEnums';
import { SegmentGroupsQuery } from '../../queries/segment';
import { AuthRoutes } from '../../enums/RouteEnums';

const openDrawerWidth = 256;
const closedDrawerWidth = 80;

const openedMixin = (theme: Theme) => ({
  width: openDrawerWidth,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen
  }),
  overflowX: 'hidden'
});

const closedMixin = (theme: Theme) => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen
  }),
  overflowX: 'hidden',
  width: closedDrawerWidth
});

const Navigation: React.FunctionComponent = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { state, dispatch } = useContext(AppContext);
  const brandId = getBrandId(state.selectedBrand);
  useQuery({
    queryKey: [QueryKey.SegmentGroups, brandId],
    queryFn: () => SegmentGroupsQuery(brandId),
    onSuccess: () => true
  });

  const [open, setOpen] = useState(true);
  const [anchorElProfile, setAnchorElProfile] = useState<null | HTMLElement>(null);
  const [navSelected, setNavSelected] = useState<IRouteModel | null>(null);
  const [subNavSelected, setSubNavSelected] = useState<IRouteModel | null>(null);
  const [detailsRoute, setDetailsRoute] = useState<IRouteModel | null>(null);
  const [isCardDetails, setIsCardDetails] = useState(false);
  const queryClient = useQueryClient();

  useEffect(() => {
    const locationElements = location.pathname.split('/').filter((e) => e);
    const locationName =
      locationElements.length === 2 ? `/${locationElements[0]}/:id` : '/' + locationElements[0];
    setIsCardDetails(locationName === AuthRoutes.PlayersCardDetails);
    const navName = locationElements.length === 2 ? '/' + locationElements[0] : locationName;
    const [navRoute, subNavRoute] = getNavSelectedByPath(navName);
    setNavSelected(navRoute);
    setSubNavSelected(subNavRoute);
    if (locationElements.length === 2) {
      const selectedRoute = state.routes.find((r) => r.path === locationName) ?? null;
      setDetailsRoute(selectedRoute);
    } else {
      setDetailsRoute(navRoute);
    }
  }, [location.pathname]);

  const getNavSelectedByPath = (path: string) => {
    for (let i = 0; i < state.routes.length; i++) {
      const currentRoute = state.routes[i];
      if (currentRoute.path === path) {
        return [currentRoute, null];
      } else {
        if (currentRoute.subRoutes) {
          for (let j = 0; j < currentRoute.subRoutes.length; j++) {
            const currentSubRoute = currentRoute.subRoutes[j];
            if (currentSubRoute.path === path) {
              return [currentRoute, currentSubRoute];
            }
          }
        }
      }
    }
    return [null, null];
  };

  const handleSelectedChanged = (route: IRouteModel, subRoute?: IRouteModel) => {
    setNavSelected(route);
    if (subRoute) {
      navigate(subRoute.path as string);
      setSubNavSelected(subRoute);
    } else {
      navigate(route.path as string);
      setSubNavSelected(null);
    }
  };

  const handleLogout = () => {
    localStorage.removeItem(AppConsts.AccessToken);
    dispatch({
      type: Actions.RemoveUser
    });
    setAnchorElProfile(null);
    queryClient.invalidateQueries();
  };

  return state.user.data ? (
    <Box sx={{ display: 'flex' }}>
      {!isCardDetails && (
        <AppBar
          position="fixed"
          sx={(theme) => ({
            transition: theme.transitions.create(['width', 'margin'], {
              easing: theme.transitions.easing.sharp,
              duration: theme.transitions.duration.leavingScreen
            }),
            backgroundColor: colors.grey.thread,
            marginLeft: closedDrawerWidth,
            height: '80px',
            justifyContent: 'center',
            width: `calc(100% - ${closedDrawerWidth}px)`,
            boxShadow: 'inset 0px -1px 0px #E8F5FF',
            ...(open && {
              marginLeft: openDrawerWidth,
              width: `calc(100% - ${openDrawerWidth}px)`,
              transition: theme.transitions.create(['width', 'margin'], {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.enteringScreen
              })
            })
          })}
        >
          <Toolbar sx={{ padding: '0 40px !important' }}>
            <Box
              sx={{
                flexGrow: 1,
                color: 'primary.main',
                fontSize: '28px',
                fontStyle: 'normal',
                fontWeight: 600
              }}
            >
              {detailsRoute?.toNavRoute && (
                <IconButton
                  onClick={() =>
                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                    // @ts-ignore
                    navigate(location.state?.path ?? (detailsRoute?.toNavRoute as string))
                  }
                  sx={{
                    height: '34px',
                    width: '34px',
                    position: 'relative',
                    left: '-5px',
                    top: '-2.5px'
                  }}
                >
                  <ArrowBack sx={{ color: 'primary.main' }} />
                </IconButton>
              )}
              {detailsRoute?.name}
            </Box>
            <Box className="centralize" sx={{ flexGrow: 0 }}>
              <Box
                className="centralize"
                sx={{
                  width: '140px',
                  height: '40px',
                  background: colors.white,
                  marginRight: '8px',
                  borderRadius: '50px',
                  color: colors.primary.main,
                  cursor: 'pointer'
                }}
              >
                {/* <Notifications /> */}
                <BrandSelect />
              </Box>
              <Box
                className="centralize"
                sx={{
                  width: '40px',
                  height: '40px',
                  background: colors.white,
                  borderRadius: '50px',
                  cursor: 'pointer'
                }}
                onClick={(e) => setAnchorElProfile(e.currentTarget)}
              >
                <AccountCircle
                  sx={{
                    width: '20px',
                    height: '20px',
                    color: colors.primary.main
                  }}
                />
              </Box>
              <Menu
                sx={{ mt: '48px' }}
                id="menu-profile"
                anchorEl={anchorElProfile}
                transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                anchorOrigin={{ horizontal: 'right', vertical: 'top' }}
                keepMounted
                open={Boolean(anchorElProfile)}
                onClose={() => setAnchorElProfile(null)}
                MenuListProps={{ sx: { p: '4px 0', minWidth: '180px' } }}
              >
                <MenuItem sx={{ padding: '8px 10px' }} onClick={handleLogout}>
                  <ListItemIcon
                    sx={{
                      minWidth: 'auto !important',
                      marginRight: '11px',
                      color: 'info.main'
                    }}
                  >
                    <Logout fontSize="small" />
                  </ListItemIcon>
                  <ListItemText>Log out</ListItemText>
                </MenuItem>
              </Menu>
            </Box>
          </Toolbar>
        </AppBar>
      )}
      <Drawer
        variant="permanent"
        open={open}
        onClick={() => setOpen(!open)}
        PaperProps={{
          sx: {
            boxShadow: 'inset -1px 0px 0px #E8F5FF',
            border: 'none',
            background: colors.grey.thread
          }
        }}
        sx={(theme) => ({
          width: openDrawerWidth,
          flexShrink: 0,
          whiteSpace: 'nowrap',
          boxSizing: 'border-box',
          border: 'none',
          ...(open && {
            ...openedMixin(theme),
            '& .MuiDrawer-paper': openedMixin(theme)
          }),
          ...(!open && {
            ...closedMixin(theme),
            '& .MuiDrawer-paper': closedMixin(theme)
          })
        })}
      >
        <Box sx={{ padding: '20px 24px', height: '80px' }}>
          <Box
            component="img"
            src={logo}
            alt="logo"
            sx={{
              width: open ? 'auto' : '40px',
              height: '40px',
              cursor: 'pointer'
            }}
          />
        </Box>
        <Box sx={{ padding: '8px' }}>
          <List>
            {state.routes
              .filter((r) => !r.isPublic && r.showInNav)
              .map((r, index) =>
                r.subRoutes ? (
                  <ListGroupLink
                    key={index}
                    open={open}
                    route={r}
                    selected={navSelected}
                    subSelected={subNavSelected}
                    handleSelectedChanged={handleSelectedChanged}
                    userRoles={state.user.data?.roles}
                  />
                ) : (
                  <ListItemLink
                    key={index}
                    open={open}
                    route={r}
                    selected={navSelected}
                    handleSelectedChanged={handleSelectedChanged}
                    userRoles={state.user.data?.roles}
                  />
                )
              )}
          </List>
        </Box>
        {open && (
          <Box
            component="img"
            src={hand}
            alt="logo"
            sx={{
              maxHeight: '430px',
              width: '150px',
              position: 'absolute',
              bottom: '0px',
              left: '50px',
              display: {
                xs: 'none', // Hide on extra-small screens (phones)
                sm: 'none', // Hide on small screens (tablets)
                md: 'block' // Show on medium screens and larger (desktop)
              }
            }}
          />
        )}
      </Drawer>
    </Box>
  ) : null;
};

export default Navigation;
