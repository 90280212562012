import React from 'react';
import {
  AssignmentInd,
  Campaign,
  Dashboard,
  HowToReg,
  Settings,
  SvgIconComponent,
  Email,
  Attractions,
  OpenInNew
} from '@mui/icons-material';
import { AuthRoutes, PublicRoutes, RouteNames } from './enums/RouteEnums';
import Login from './components/Account/Login';
import ResetPassword from './components/Account/ResetPassword';
import LeadManagement from './components/LeadManagement';
import SettingsComponent from './components/Settings/SettingsComponent';
import Segments from './components/CampaignManager/Segments/SegmentGroupsOverview';
import Journeys from './components/CampaignManager/CustomerJourney/JourneyGroupOverview';
import JourneyDetails from './components/CampaignManager/CustomerJourney/JourneyDetails/JourneyDetails';
import Promotions from './components/CampaignManager/Promotions';
import ManagePopups from './components/CampaignManager/Popups/ManagePopups';
import SchedulePopups from './components/CampaignManager/Popups/SchedulePopups';
import Home from './components/Home';
import { Role } from './enums/AppConsts';
import PlayerCardsOverview from './components/Player/PlayerCardsOverview';
import PlayerCardDetails from './components/Player/PlayerCardDetails';
import EmailsOverview from './components/Emails/EmailsOverview';
import PlaceHoldersOverview from './components/Emails/PlaceHoldersOverview';
import Logs from './components/Emails/LogsOverview';

export interface IRouteModel {
  id?: number;
  name: string;
  path?: string;
  Component?: React.FunctionComponent;
  requiredRole?: string | null;
  isPublic: boolean;
  icon?: SvgIconComponent;
  showInNav: boolean;
  toNavRoute?: string;
  subRoutes?: IRouteModel[];
}

const Routes: IRouteModel[] = [
  {
    name: RouteNames.Login,
    path: PublicRoutes.Login,
    Component: Login,
    isPublic: true,
    showInNav: false
  },
  {
    name: RouteNames.ResetPass,
    path: PublicRoutes.ResetPass,
    Component: ResetPassword,
    isPublic: true,
    showInNav: false
  },
  {
    name: RouteNames.Home,
    path: AuthRoutes.Home,
    Component: Home,
    isPublic: false,
    icon: Dashboard,
    showInNav: true
  },
  {
    name: RouteNames.Management,
    path: AuthRoutes.Management,
    requiredRole: Role.Marketing,
    Component: LeadManagement,
    isPublic: false,
    showInNav: true,
    icon: HowToReg
  },
  {
    name: RouteNames.PlayersCards,
    path: AuthRoutes.PlayersCards,
    Component: PlayerCardsOverview,
    isPublic: false,
    showInNav: true,
    icon: AssignmentInd
  },
  {
    name: RouteNames.PlayersCards,
    path: AuthRoutes.PlayersCardDetails,
    Component: PlayerCardDetails,
    isPublic: false,
    showInNav: false,
    icon: HowToReg
  },
  {
    name: RouteNames.CampaignManager,
    path: `${AuthRoutes.CustomerJourney}/:id`,
    Component: Journeys,
    isPublic: false,
    showInNav: false,
    icon: HowToReg
  },
  {
    name: RouteNames.CampaignManager,
    path: `${AuthRoutes.CustomerJourney}/:groupId/:journeyId`,
    Component: JourneyDetails,
    isPublic: false,
    showInNav: false,
    icon: HowToReg
  },
  {
    name: RouteNames.CampaignManager,
    path: `${AuthRoutes.Promotions}/:id`,
    Component: Promotions,
    isPublic: false,
    showInNav: false,
    icon: HowToReg
  },
  {
    name: RouteNames.CampaignManager,
    isPublic: false,
    showInNav: true,
    icon: Campaign,
    requiredRole: Role.CampaignManager,
    subRoutes: [
      {
        name: RouteNames.Segments,
        path: AuthRoutes.Segments,
        Component: Segments,
        requiredRole: Role.CampaignManager,
        isPublic: false,
        showInNav: true
      },
      {
        name: RouteNames.CustomerJourney,
        path: AuthRoutes.CustomerJourney,
        Component: Journeys,
        requiredRole: Role.CampaignManager,
        isPublic: false,
        showInNav: true
      },
      {
        name: RouteNames.Promotions,
        path: AuthRoutes.Promotions,
        Component: Promotions,
        requiredRole: Role.CampaignManager,
        isPublic: false,
        showInNav: true
      }
    ]
  },
  {
    name: RouteNames.Popups,
    path: AuthRoutes.Popups,
    requiredRole: Role.PopupOperator,
    isPublic: false,
    showInNav: true,
    icon: OpenInNew,
    subRoutes: [
      {
        name: RouteNames.ManagePopups,
        path: AuthRoutes.ManagePopups,
        Component: ManagePopups,
        requiredRole: Role.PopupOperator,
        isPublic: false,
        showInNav: true
      },
      {
        name: RouteNames.SchedulePopups,
        path: AuthRoutes.SchedulePopups,
        Component: SchedulePopups,
        requiredRole: Role.PopupOperator,
        isPublic: false,
        showInNav: true
      }
    ]
  },
  // {
  //   name: RouteNames.Reports,
  //   path: AuthRoutes.Reports,
  //   Component: () => <Box>Reports</Box>,
  //   isPublic: false,
  //   showInNav: true,
  //   icon: Assessment
  // },
  // {
  //   name: RouteNames.Users,
  //   path: AuthRoutes.Users,
  //   Component: () => <Box>Users & Permissions</Box>,
  //   isPublic: false,
  //   showInNav: true,
  //   icon: Group
  // },
  {
    name: RouteNames.Emails,
    isPublic: false,
    showInNav: true,
    icon: Email,
    // requiredRole: Role.EmailOperator,
    subRoutes: [
      {
        name: RouteNames.EmailEvents,
        path: AuthRoutes.Emails,
        Component: EmailsOverview,
        requiredRole: Role.EmailOperator,
        isPublic: false,
        showInNav: true
      },
      {
        name: RouteNames.PlaceHolders,
        path: AuthRoutes.PlaceHolders,
        Component: PlaceHoldersOverview,
        //requiredRole: Role.EmailOperator,
        isPublic: false,
        showInNav: true
      },
      {
        name: RouteNames.EmailLogs,
        path: AuthRoutes.EmailLogs,
        Component: Logs,
        //requiredRole: Role.EmailOperator,
        isPublic: false,
        showInNav: true
      }
    ]
  },
  {
    name: RouteNames.Settings,
    path: AuthRoutes.Settings,
    Component: SettingsComponent,
    isPublic: false,
    showInNav: true,
    icon: Settings
  }
];

export const getRoutes = (): IRouteModel[] => {
  let index = 0;
  const result: IRouteModel[] = [];
  Routes.forEach((r: IRouteModel) => {
    result.push({ id: index++, ...r });
  });
  return result;
};
